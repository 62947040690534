
import { defineComponent } from "vue";
import moment from "moment";
import { ElMessage } from "element-plus";
import ApiService from "@/core/services/ApiService";
import { hideModal } from "@/core/helpers/dom";

export default defineComponent({
  name: "add-server-modal",
  emits: ["markedDone"],
  props: ["requestData"],
  components: {},
  data() {
    return {
      refRequestDetailModal: "refRequestDetailModal",
    };
  },
  methods: {
    async markAsDone() {
      try {
        await ApiService.vueInstance.axios.post(
          `request/v1/${this.requestData?.id}/done`
        );
        this.$emit("markedDone");
        setTimeout(() => {
          hideModal(this.$refs[this.refRequestDetailModal] as HTMLElement);
        }, 500);
        ElMessage({
          showClose: true,
          message: `Marked ${this.requestData?.requester} request to ${this.requestData?.request_type?.name} as Done`,
          type: "success",
        });
      } catch (error) {
        if (error instanceof Error) {
          // ✅ TypeScript knows err is Error
          ElMessage.error(error.message);
        } else {
          ElMessage.error("Unexpected error");
        }
      }
    },
    getFullDate: function (unixDate) {
      const normalDateFromUnix = new Date(unixDate * 1000);
      const date = new Date(normalDateFromUnix);
      return moment(date).isValid()
        ? moment(date).format("DD MMM YYYY HH:MM")
        : "-";
    },
  },
});
