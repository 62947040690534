
import { defineComponent, ref } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import RequestDetail from "@/views/requests/RequestDetail.vue";
import { ElMessage } from "element-plus";
import ApiService from "@/core/services/ApiService";

interface IType {
  id?: number;
  name: string;
}

interface IApprover {
  id: number;
  short_id: string;
  request_id: number;
  approver: string;
  is_approved: boolean;
  created_at: number;
  updated_at: number;
}

interface IRequestList {
  id?: number | null;
  requester: string;
  request_type: IType;
  request_status: IType;
  description: string;
  is_approved: boolean;
  created_at: number;
  updated_at: number;
  approver?: IApprover[];
  isApprovable?: boolean;
}
export default defineComponent({
  name: "request-access-list",
  components: {
    Datatable,
    RequestDetail,
  },
  data() {
    return {
      requests: ref<Array<IRequestList>>([]),
      headers: [
        {
          name: "Requester",
          key: "requester",
          sortable: true,
        },
        {
          name: "Access Type",
          key: "request_type",
          sortable: true,
        },
        {
          name: "Approval Count",
          key: "approvers_count",
          sortable: true,
        },
        {
          name: "Status",
          key: "request_status",
          sortable: true,
        },
      ],
      initialData: ref<Array<IRequestList>>([]),
      search: ref<string>(""),
      clickedData: ref<IRequestList | null>(null),
    };
  },
  methods: {
    errorHandler(errorData) {
      if (errorData instanceof Error) {
        // ✅ TypeScript knows err is Error
        ElMessage.error(errorData.message);
      } else {
        ElMessage.error("Unexpected error");
      }
    },
    async onResponseClicked(row) {
      try {
        const requestApproverList = await ApiService.vueInstance.axios(
          `request/v1/${row.id}/approvers`
        );
        const approverList = requestApproverList.data.data.filter(
          (approve) => approve.is_approved
        );
        const isApprovable = row.approvers_count >= 2;

        this.clickedData = { ...row, approver: approverList, isApprovable };
      } catch (error) {
        this.errorHandler(error);
      }
    },
    keyMatches(obj, value): boolean {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (
            obj[key].toString().toLowerCase().indexOf(value.toLowerCase()) != -1
          ) {
            return true;
          }
        }
      }
      return false;
    },
    searchRequest() {
      this.requests.splice(0, this.requests.length, ...this.initialData);
      if (this.search !== "") {
        let results: Array<IRequestList> = [];
        for (let i = 0; i < this.requests.length; i++) {
          if (this.keyMatches(this.requests[i], this.search)) {
            results.push(this.requests[i]);
          }
        }
        this.requests.splice(0, this.requests.length, ...results);
      }
    },
    async getRequestList() {
      try {
        const requestList = await await ApiService.vueInstance.axios(
          "request/v1/list?filter_status=0"
        );
        this.initialData.splice(
          0,
          this.initialData.length,
          ...requestList.data.data
        );
        this.requests.splice(0, this.requests.length, ...requestList.data.data);
      } catch (error) {
        this.errorHandler(error);
      }
    },
  },
  mounted() {
    this.getRequestList();
  },
});
