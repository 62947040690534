import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header border-0 pt-6" }
const _hoisted_3 = { class: "d-flex align-items-center position-relative my-1" }
const _hoisted_4 = { class: "svg-icon svg-icon-1 position-absolute ms-6" }
const _hoisted_5 = { class: "card-body pt-0" }
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Datatable = _resolveComponent("Datatable")!
  const _component_RequestDetail = _resolveComponent("RequestDetail")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen021.svg" })
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
            onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchRequest())),
            class: "form-control form-control-solid w-250px ps-15",
            placeholder: "Search Member"
          }, null, 544), [
            [_vModelText, _ctx.search]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_Datatable, {
          "table-data": _ctx.requests,
          "table-header": _ctx.headers,
          "enable-items-per-page-dropdown": true
        }, {
          "cell-requester": _withCtx(({ row: server }) => [
            _createTextVNode(_toDisplayString(server.requester), 1)
          ]),
          "cell-request_type": _withCtx(({ row: server }) => [
            _createTextVNode(_toDisplayString(server.request_type.name), 1)
          ]),
          "cell-approvers_count": _withCtx(({ row: server }) => [
            _createTextVNode(_toDisplayString(server.approvers_count) + " / " + _toDisplayString(server.total_approver), 1)
          ]),
          "cell-request_status": _withCtx(({ row: server }) => [
            _createElementVNode("p", {
              onClick: ($event: any) => (_ctx.onResponseClicked(server)),
              className: "response-button",
              "data-bs-toggle": "modal",
              "data-bs-target": "#kt_req_detail_modal"
            }, _toDisplayString(server.request_status.name), 9, _hoisted_6)
          ]),
          _: 1
        }, 8, ["table-data", "table-header"])
      ])
    ]),
    _createVNode(_component_RequestDetail, {
      requestData: _ctx.clickedData,
      onMarkedDone: _ctx.getRequestList
    }, null, 8, ["requestData", "onMarkedDone"])
  ], 64))
}